/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { IDetailsListStyleProps, IDetailsListStyles } from '@fluentui/react/';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

import * as StyleConstants from '../Constants';

export const DetailsListStyles = (props: IDetailsListStyleProps): Partial<IDetailsListStyles> => {
  const { theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const {
    detailsListContentBorder,
    detailsListContentHoverBackground,
    detailsListContentHoverSelectedBackground,
    detailsListContentSelectedBackground,
    detailsListContentText,
    detailsListHeaderBorder,
    detailsListHeaderText,
    listBackground
  } = extendedSemanticColors;

  return {
    headerWrapper: {
      '.ms-DetailsHeader': {
        height: `${StyleConstants.headerHeight}`,
        lineHeight: `${StyleConstants.headerHeight}`,
        borderBottom: `1px solid ${detailsListHeaderBorder}`,
        background: extendedSemanticColors.detailsListHeaderBackground
      },
      '.ms-DetailsHeader-cell': {
        height: `${StyleConstants.headerHeight}`,
        width: StyleConstants.detailsListCheckWidth,
      },
      '.ms-DetailsHeader-cell:hover': {
        background: extendedSemanticColors.pivotBackgroundHover
      },
      '.ms-DetailsHeader-cellName': {
        fontSize: `${StyleConstants.fontSize}`,
        fontWeight: 'bold',
        color: detailsListHeaderText,
      },
      '.ms-Check-checkHost': {
        width: StyleConstants.detailsListCheckWidth,
      },
    },
    contentWrapper: {
      '.ms-List-cell': {
        minHeight: `${StyleConstants.rowHeight}`,
      },
      '.ms-DetailsRow': {
        minHeight: `${StyleConstants.rowHeight}`,
        borderBottom: `1px solid ${detailsListContentBorder}`,
        background: listBackground
      },
      '.ms-DetailsRow:hover': {
        background: detailsListContentHoverBackground,
      },
      '.ms-DetailsRow-cell': {
        color: detailsListContentText,
        fontSize: `${StyleConstants.fontSize}`,
        minHeight: `${StyleConstants.rowHeight}`,
        paddingBottom: '8px',
        paddingTop: '8px',
      },
      '.ms-DetailsRow-cellCheck': {
        fontSize: `${StyleConstants.fontSize}`,
        minHeight: `${StyleConstants.rowHeight}`,
        paddingBottom: '0px',
        paddingTop: '0px',
      },
      '.ms-DetailsRow-check': {
        height: `${StyleConstants.rowHeight}`,
      },
      '.ms-DetailsRow.is-selected > .ms-DetailsRow-fields > .ms-DetailsRow-cell:first-child': {
        fontWeight: 'bold',
      },
      '.ms-DetailsRow.is-selected:focus > .ms-DetailsRow-fields > .ms-DetailsRow-cell:first-child, .ms-DetailsRow.is-selected:hover > .ms-DetailsRow-fields > .ms-DetailsRow-cell:first-child': {
        color: detailsListContentText,
      },
      '.is-selected, .is-selected:focus': {
        background: detailsListContentSelectedBackground,
      },
      '.is-selected:hover': {
        background: detailsListContentHoverSelectedBackground,
      },
      '.is-selected:focus:hover': {
        background: detailsListContentHoverSelectedBackground,
      },
      '.ms-Check-checkHost': {
        width: StyleConstants.detailsListCheckWidth,
      },
      ':hover .is-row-header': {
        color: `${detailsListContentText} !important`,
      }
    },
  };
};
