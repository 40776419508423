/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { ITooltipHostStyleProps, ITooltipStyles } from '@fluentui/react';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';


export const TooltipStyles = (props: ITooltipHostStyleProps): Partial<ITooltipStyles> => {
  const { theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { tooltipBackgroundColor, tooltipColor } = extendedSemanticColors;
  return {
    root: [
      {
        backgroundColor: tooltipBackgroundColor,
        boxShadow: '0 1px 3px 0 rgba(0,0,0,0.30)',
        borderRadius: 4,
        selectors: { '>.ms-Callout-beakCurtain': { borderRadius: 4 } },
      },
    ],
    content: [
      {
        backgroundColor: tooltipBackgroundColor,
        color: tooltipColor
      },
    ],
  };
};
