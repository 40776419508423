/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { ITheme } from '@fluentui/react/';
import { IComboBoxStyles } from '@fluentui/react';
import * as StyleConstants from '../Constants';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const ComboBoxStyles = (theme: ITheme): Partial<IComboBoxStyles> => {
  const { semanticColors } = theme;

  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { inputBorderFocused } = extendedSemanticColors;
  return {
    root: [
      {
        fontSize: 12,
        height: StyleConstants.ComboBoxHeight,
        // padding: `1px 18px 1px 6px`, this breaks the arrow position
        selectors: {
          '&.ms-ComboBox-CaretDown-button': {
            height: StyleConstants.ComboBoxHeight,
            lineHeight: StyleConstants.ComboBoxHeight - 2,
          },
          '&.is-open::after': {
            borderColor: inputBorderFocused,
            borderWidth: 1,
          },
          '.ms-Button': {
            backgroundColor: 'transparent',
            width: 16,
            height: 16,
            paddingTop: '5px',
            cursor: 'pointer',
          },
          '.ms-Button:hover': {
            backgroundColor: 'transparent',
          },
          '.ms-Button--icon': {
            top: 4,
            right: 4,
          },
        },
      },
    ],
    rootFocused: {
      selectors: {
        '::after': {
          borderColor: inputBorderFocused,
          borderWidth: 1,
        },
      },
    },
    optionsContainer: {
      '.ms-ComboBox-optionText': {
        fontSize: 12,
      },
      '.ms-ComboBox-option': {
        lineHeight: '16px',
        minHeight: '24px'
      }
    },
    input: {
      fontSize: 12,
      height: StyleConstants.ComboBoxHeight,
      lineHeight: StyleConstants.ComboBoxHeight - 6,
    },
  };
};
